import React, { useContext } from 'react'
import Layout from '../components/Global/Layout'
import Loader from '../components/Global/Loader'
import BookFreeConsult from '../components/BookFreeConsult'
import { GlobalStateContext } from '../context/GlobalContextProvider'

const BookFreeConsultPage = (props) => {
  const state = useContext(GlobalStateContext)

  if (!state.locale) {
    return (
      <Layout locale={state.locale}>
        <Loader loading />
      </Layout>
    )
  }

  return (
    <Layout
      locale={state.locale}
      url='/book-free-consult'
      title='Book a Free Needs Assessment'
    >
      <BookFreeConsult />
    </Layout>
  )
}

export default BookFreeConsultPage
