import React, { useEffect } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import fbTrack from '../../context/facebook'

const BookFreeConsultView = ({ props }) => {
  useEffect(() => {
    window.onmessage = (event) => {
      if (event.data.meetingBookSucceeded) {
        fbTrack('track', 'Schedule')
      }
    }
  }, [])

  return (
    <div className='book-consult'>
      <Container fluid className='bg-header px-0'>
        <Container className='pt-5'>
          <Row className='justify-content-center text-center pt-5'>
            <Col md='10'>
              <div
                className='meetings-iframe-container'
                data-src='https://meetings.hubspot.com/heirlume?embed=true'
              ></div>
            </Col>
          </Row>
        </Container>
      </Container>
    </div>
  )
}

export default BookFreeConsultView
